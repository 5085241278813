.picture-option:not(.border-primary):not(.border-error) {
  border: 3px solid var(--border-color);
  border-radius: 2px;
  @apply cursor-pointer flex flex-col items-center gap-6 py-6 px-2.5;

  &:not(:hover) {
    color: var(--border-color);
  }

  &.selected {
    @apply text-primary-content bg-primary font-bold border-none;
  }
}
