$highlight-border: 2px solid var(--highlight-color);
$cell-border: 2px solid var(--light-blue-color);

// Table columns switch
.Table--switch {
  border-radius: 100px;
  border: $cell-border;

  @apply flex;
  @apply md:hidden;
  @apply justify-between;
  @apply overflow-hidden;

  .button {
    color: var(--midnightblue-color);

    @apply flex-1;
    @apply whitespace-nowrap;
    @apply font-bold;
    @apply items-center;
    @apply justify-center;
    @apply p-4;
    @apply rounded-full;
    @apply text-center;

    &--active {
      color: #fff;
      background-color: var(--highlight-color);
    }
  }
}

/**
 * MAIN TABLE STYLES
 */
.Table {
  @apply table-fixed;
  @apply w-full;

  &-mobile {
    @media (width >= 441px) {
      @apply hidden;

      .Table__cell {
        border: none;
      }
    }
  }

  &-desktop {
    @media (width <= 440px) {
      @apply hidden;
    }
  }

  &__row {
    @screen md {
      border-bottom: $cell-border;
    }

    &.trow-highlighted {
      border-top: 2px dashed var(--highlight-color);
      border-bottom: 2px dashed var(--highlight-color);
    }
  }

  &__cell {
    @apply align-middle;
    @apply flex-wrap;
    @apply font-normal;
    @apply px-2;
    @apply py-4;
    @apply text-base;
    @apply text-center;

    &:not(:first-child) {
      @screen md {
        border-left: $cell-border;
      }
    }

    &:first-child {
      @apply text-left;
    }

    img {
      @apply inline-block;
      @apply mr-3;
    }

    span + img {
      @apply ml-3;
      @apply mr-0;
    }

    &[data-status='true'],
    &[data-status='false'] {
      @apply items-center;
      @apply flex;
      @apply justify-between;

      &::before {
        @apply mr-2;
      }
    }

    &[data-status='true'] {
      &::before {
        content: url('../../../assets/icons/check-circle-green.svg');
      }
    }

    &[data-status='false'] {
      &::before {
        content: url('../../../assets/icons/minus-circle-black.svg');
      }
    }
  }

  &--head {
    th {
      @apply bg-white;
    }

    .Table__cell {
      @apply py-0;

      span {
        @apply text-xl;
        @apply font-bold;
        @apply block;
        @apply p-0;
        @apply my-4;
      }
    }
  }

  /* &--body {} */

  // Content switcher
  &[data-switch] {
    .Table__cell {
      @apply hidden;
    }
  }

  &[data-switch='1'] {
    .Table__cell:nth-child(1) {
      @apply flex;
    }
  }

  &[data-switch='2'] {
    .Table__cell:nth-child(2) {
      @apply flex;
    }
  }

  &[data-switch='3'] {
    .Table__cell:nth-child(3) {
      @apply flex;
    }
  }
}

// Grant table
.grant-table {
  @media (width >= 441px) {
    // babicka not illegible

    &:not(.grant--babicka) {
      .Table__row:last-child {
        display: none;
      }

      .Table__cell {
        &:nth-child(2) {
          @apply hidden;
        }
      }
    }

    &.grant--babicka {
      .Table__cell:nth-child(2) {
        background-color: var(--lightblue-color);
      }

      .Table--body {
        .Table__row {
          // middle cell
          .Table__cell:nth-child(2) {
            border-left: $highlight-border;
            border-right: $highlight-border;
          }
          
          // last row
          &:last-child {
            border-bottom: none;
            
            .Table__cell {
              line-height: 0;
              padding: 0.5rem;
              
              
              &:nth-child(2) {
                border-bottom: $highlight-border;
              }
              
            }
          }

          // last row with data, but its actually the second last row
          &:nth-last-child(2) {
            .Table__cell {
              // hide border for the babicka cell in the last data row
              &:nth-child(2) {
                position: relative;

                &::after {
                  bottom: -2px;
                  content: ' ';
                  height: 2px;
                  left: 0;
                  position: absolute;
                  right: 0;
                  background-color: var(--lightblue-color);
                }
              }
            }
          }
        }
      }

      .Table--head {
        .Table__cell:nth-child(2) {
          @apply p-0;

          border-left: $highlight-border;
          border-right: $highlight-border;

          [data-precell] {
            background-color: var(--highlight-color);
            color: #fff;

            @apply text-xl;
            @apply font-normal;
            @apply py-4;
            @apply block;
            @apply my-0;

            margin: 0 -1px;
          }
        }

        .Table__cell:nth-child(3) {
          @apply align-bottom;
        }
      }
    }
  }


  // MOBILE Grant TABLE
  &.Table-mobile {
    .Table--head {
      span[data-precell] {
        @apply px-2;
        @apply py-1;
        @apply text-center;

        background-color: #d8ebf9;
      }
    }

    .Table__cell {
      @apply w-full;

      span {
        @apply text-left;
        @apply flex-1;
      }
    }
  }
}

// Grant progress table
.grant-progress-table {
  @apply relative;

  .Table__row {
    @apply border-none;
  }

  .Table__cell {
    @apply border-none;
    @apply text-left;
    @apply text-lg;
    @apply py-2;

    [data-line] {
      @apply absolute;

      top: 40px;
      bottom: 44px;

      @apply absolute;
      @apply flex;
      @apply w-4;

      margin-left: 1px;
      border-right: 2px dashed var(--babyblue-color);

      @screen sm {
        top: 108px;
      }

      &::after {
        content: url('../../../assets/icons/chevron-line-tip.svg');

        @apply absolute;
        @apply -bottom-4;

        margin-left: 7px;
      }
    }

    [data-stepid] {
      @apply relative;
      @apply not-italic;
      @apply inline-flex;
      @apply w-8;
      @apply h-8;
      @apply items-center;
      @apply justify-center;
      @apply mr-4;

      border-radius: 50%;
      background-color: var(--babyblue-color);
      color: #036;
      z-index: 2;

      &[data-red] {
        color: #fff;
        background-color: var(--red-color);
      }

      &[data-blue] {
        color: #fff;
        background-color: var(--midnightblue-color);
      }
    }
  }

  &.Table-mobile {
    [data-emptycell] {
      @apply py-0;
    }
  }

  &:not(.grant--babicka) {
    [data-line] {
      top: 100px;
    }

    .Table--body {
      .Table__cell {
        &:nth-child(1) {
          @apply hidden;
        }
      }
    }
  }
}
