.Modal {
  @apply bg-white;
  @apply max-w-2xl;
  @apply md:mx-auto;
  @apply lg:w-3/6;
  @apply rounded;

  box-shadow: 0 4px 10px 0 rgb(0 51 102 / 25%);

  &::backdrop {
    @apply bg-white;
    @apply opacity-70;
  }

  &--close {
    @apply absolute;
    @apply w-6;
    @apply h-6;
    @apply flex;
    @apply top-6;
    @apply right-6;

    button {
      all: unset;

      @apply cursor-pointer;

      &:hover {
        @apply opacity-60;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
