@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Roboto Condensed';
  src:
    local('RobotoCondensed-Regular'),
    url('./fonts/RobotoCondensed-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Condensed Bold';
  src:
    local('RobotoCondensed-Bold'),
    url('./fonts/RobotoCondensed-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Condensed Light';
  src:
    local('RobotoCondensed-Light'),
    url('./fonts/RobotoCondensed-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Condensed Italic';
  src:
    local('RobotoCondensed-Italic'),
    url('./fonts/RobotoCondensed-Italic.ttf') format('truetype');
}

:root {
  --headline-color: #036;
  --lightblue-color: #ebf6fd;
  --darkblue-color: #333;
  --midnightblue-color: #036;
  --babyblue-color: #a0d2f6;
  --red-color: #ff6000;
  --red-dark-color: #f00;
  --border-color: #8e9aa5;
  --border-diabled-color: #d2d2d2;
  --light-blue-color: #d8ebf9;
  --link-color: #0a77a9;
  --highlight-color: #009acb;
  --rt-opacity: 1 !important;
  --step-inactive-color: #bccfdf;
}

body {
  margin: 0;
  font-family: 'Roboto Condensed', Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--lightblue-color);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  @screen sm {
    font-size: 18px;
    line-height: 22px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

strong,
.text-bold {
  font-weight: 700;
}

small,
.text-sm {
  font-size: 14px;
  line-height: 16px;

  @screen sm {
    font-size: 16px;
    line-height: 19px;
  }
}

h1 {
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;

  @screen sm {
    font-size: 50px;
    line-height: 60px;
  }
}

h2,
h3,
h4,
h5,
h6 {
  color: var(--headline-color);
}

h2 {
  font-size: 25px;
  line-height: 30px;

  @screen sm {
    font-size: 36px;
    line-height: 42px;
  }
}

h3 {
  font-size: 21px;
  line-height: 25px;

  @screen sm {
    font-size: 26px;
    line-height: 30px;
  }
}

h4,
h5,
h6 {
  @apply font-bold;
}

h4 {
  font-size: 18px;
  line-height: 22px;

  @screen sm {
    font-size: 24px;
    line-height: 28px;

    @apply font-normal;
  }
}

h5 {
  font-size: 17px;
  line-height: 20px;

  @screen sm {
    font-size: 20px;
    line-height: 23px;
  }
}

h6 {
  font-size: 16px;
  line-height: 19px;

  @screen sm {
    font-size: 18px;
    line-height: 21px;
  }
}

a {
  &.link--red {
    color: var(--red-dark-color);
  }
}

// Reset webflow global CSS
.label {
  @apply px-0 mb-0 font-normal;
}

// Reset webflow global CSS
.checkbox {
  border-style: solid;
}

// Reset webflow global CSS
.grid:not([class*='gap-']) {
  grid-gap: 0;
}

.label-text {
  font-size: 18px;
  line-height: 22px;
}

.link-primary,
.btn-link,
.text-link {
  color: var(--link-color);
}

.btn-link,
.btn-link:hover {
  text-decoration: none;
}

.block:only-of-type,
.block:not(:last-of-type) {
  @apply my-8;

  @screen sm {
    @apply my-14;
  }
}

.btn {
  font-size: 16px;
  line-height: 19px;

  @apply h-auto min-h-min px-5 py-2 font-bold;

  @screen sm {
    font-size: 19px;
    line-height: 22px;

    @apply py-2.5;
  }

  &.btn-link {
    @apply p-0;
  }

  // TODO: variantu pro primary
  &.btn-outlined {
    @apply border-2;
    @apply bg-white;
    @apply transition-opacity;
    @apply transition-colors;
    @apply duration-200;
    
    
    &.btn-primary {
      @apply border-primary;
      @apply text-primary;

      &:hover {
        @apply bg-primary;
        @apply text-white;
      }
    }
    
    &.btn-secondary {
      @apply border-secondary;
      @apply text-secondary;

      &:hover {
        @apply bg-secondary;
        @apply text-white;
      }
    }


    
  }
}

.table {
  tr th,
  tr td {
    border-bottom-width: 2px;
    border-color: var(--light-blue-color);

    &:not(:last-child) {
      border-right-width: 2px;
    }
  }

  &.full-border {
    td {
      border-right-width: 2px;

      &:first-child {
        border-left-width: 2px;
      }
    }
  }

  // Reset DaisyUI sticky table header which overlaid some tooltips
  th:first-child {
    position: static;
  }

  thead {
    th {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;

      @apply bg-primary text-primary-content normal-case rounded-none py-2.5;

      &:first-of-type {
        @apply pl-4;
      }

      &:last-of-type {
        @apply pr-4;
      }
    }
  }

  tbody th,
  td {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  .label-text {
    font-size: 16px;
    line-height: 19px;
  }
}

.form-control:not(.switcher) {
  @apply mb-4;
}

.bg-lightblue {
  background: var(--lightblue-color);
}

.bg-table-border {
  background-color: var(--light-blue-color);
}

.bg-darkblue {
  background-color: var(--highlight-color);
}

.text-darkblue {
  color: var(--darkblue-color);
}

.text-border-color {
  color: var(--border-color);
}

.border-headline-color {
  border-color: var(--headline-color);
}

.border-border-color {
  border-color: var(--border-color);
}

.border-table-border-color {
  border-color: var(--light-blue-color);
}

.collapse-icon {
  @apply transition-transform duration-300;
}

.collapse-open .collapse-title .collapse-icon {
  @apply rotate-180;
}

.select,
.input {
  font-size: 18px;
  line-height: 22px;

  @apply font-normal border-2;

  &:not(.select-error, .select-success, .input-error, .input-success, :focus, [disabled]) {
    border-color: var(--border-color);
  }

  &[data-placeholder='true'] {
    color: var(--border-color);
  }

  &:focus {
    border-color: #09c;
    box-shadow: 0 0 0 3px rgb(0 153 204 / 50%);
    outline: 0;

    &.select-error {
      border-color: hsl(var(--er));
      box-shadow: 0 0 0 3px rgb(255 0 29 / 40%);
    }
  }

  &[disabled] {
    background-color: hsl(var(--nc));
    border-color: var(--border-diabled-color);
    color: var(--border-color);
    -webkit-text-fill-color: var(--border-color); /* Safari fix */
  }
}

.radio,
.checkbox {
  border-width: 2px;

  &:not(.radio-error, .radio-success, .checkbox-error, .checkbox-success) {
    border-color: var(--border-color);
  }
}
