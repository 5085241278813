.grant-type-card {
  display: flex;
  flex-direction: column;
  opacity: 1;

  &:not(.selectable).disabled {
    opacity: 0.5;
  }

  &.unknown {
    .heading {
      background-color: var(--border-color);
    }

    .body {
      background-color: #dfe5ea;

      li:before {
        background-image: url(../../../assets/icons/check-circle-black.svg);
      }
    }
  }

  &.selectable {
    cursor: pointer;
    border: 2px solid hsl(var(--p));

    .heading,
    .body {
      background-color: white;
      color: hsl(var(--p));
    }

    .body {
      padding: 8px 16px 16px;

      li {
        &:before {
          background-image: url(../../../assets/icons/check-circle-primary.svg);
        }

        &.condition:before {
          background-image: url(../../../assets/icons/exclamation-triangle-primary.svg);
        }
      }
    }

    &.selected {
      border-color: var(--headline-color);

      .heading,
      .body {
        background-color: var(--headline-color);
        color: white;
      }

      .body {
        li {
          &:before {
            background-image: url(../../../assets/icons/check-circle-lightblue.svg);
          }

          &.condition:before {
            background-image: url(../../../assets/icons/exclamation-triangle-lightblue.svg);
          }
        }
      }
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .heading {
    color: white;
    background-color: hsl(var(--p));
    padding: 16px;
  }

  .body {
    background-color: var(--lightblue-color);
    padding: 24px 16px;
    flex-grow: 1;

    li {
      display: flex;
      align-items: center;

      &:before {
        content: '';
        display: block;
        flex-shrink: 0;
        width: 22px;
        height: 22px;
        margin-right: 16px;
        background-image: url(../../../assets/icons/check-circle-green.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    li:not(:last-child) {
      margin-bottom: 14px;
    }
  }
}
