#my-tooltip {
  padding: 40px 24px;
  font-size: 16px;
  line-height: 19px;
  box-shadow: 0 4px 10px rgba(0, 51, 102, 0.2);
  border-radius: 5px;
  max-width: min(calc(100% - 10px), 20rem);

  @screen sm {
    padding: 20px;
  }
}
