.loading {
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(2px);
  @apply absolute top-0 left-0 bottom-0 right-0 flex flex-col items-center justify-center transition-opacity pointer-events-none;
  opacity: 0;

  &.show {
    opacity: 1;
    @apply pointer-events-auto;
  }
}
