.steps {
  gap: 10px;

  .step {
    min-height: auto;

    &:before {
      background: rgba(255, 255, 255, 0.5);
      width: 2px;
      height: 120%;
    }

    &:after {
      background: hsl(var(--pf));
      width: 18px;
      height: 18px;
    }

    &[data-content='check']:after {
      content: '';
      background-image: url(../../assets/icons/step-check.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .step-primary:after,
  .sm\:step-primary:after {
    background-color: #ffffff !important;
    color: hsl(var(--su)) !important;
    width: 22px;
    height: 22px;
  }
}

.side-menu {
  @screen lg {
    min-height: min(100vh, 100%);
  }
}
