.CallBack {
  @apply items-center;
  @apply max-w-6xl ;
  @apply m-auto;
  @apply p-4;
  @apply mt-2;
  @apply bg-white;
  @apply text-black;
  @apply gap-2;
  @apply flex;
  @apply justify-between;
  @apply border;
  
  .btn {
    img {
      @apply hidden;
    }
  }
}