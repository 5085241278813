/* TODO change into components/tailwind */

.Layout {
  &--header {
    background: #fff;

    & > div {
      display: flex;
      align-items: center;
      height: 88px;
      max-width: 72rem;
      margin: auto;
      padding: 0 1rem;
    }
  }

  &--footer {
    background-color: #f3f3f3;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      padding: 1.5rem 1rem;
      font-size: 16px;
      line-height: 19px;
      max-width: 72rem;
      margin: auto;
    }

    @media (width >= 640px) {
      font-size: 18px;
      line-height: 22px;

      & > div {
        flex-direction: row;
        gap: 1.5rem;
        height: 62px;
        padding: 0 1rem;
      }
    }
  }
}
