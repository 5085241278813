.Savings {
  .block {
    h4 {
      @apply text-base;
      @screen md {
        @apply text-xl;
      }
      @apply font-bold;
    }
  }
  &--results {
    background-color: var(--light-blue-color);
    @apply mt-4;
    @apply p-6;
    @apply gap-6;

    & > span {
      line-height: 0;
    }

    &__item {
      @apply flex-1;
      @apply flex;
      @apply flex-col;
      // @apply py-4;
      @apply text-center;
      color: var(--midnightblue-color);

      span {
        @screen md {
          @apply mb-2;
        }
        @apply text-lg;
        @apply whitespace-nowrap;
      }

      b {
        @apply text-lg;
        @screen md {
          @apply text-2xl;
        }
      }

      &.--saving {
        background-color: var(--highlight-color);
        color: #fff;
        width: 100%;
        @apply py-6;
        @screen md {
        }
      }
    }
  }
}
