input[type='range'] {
  -webkit-appearance: none;
  height: 7px;
  background: var(--border-color);
  border-radius: 5px;
  background-image: linear-gradient(hsl(var(--p)), hsl(var(--p)));
  background-size: 50% 100%;
  background-repeat: no-repeat;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fff;
  border: 5px solid hsl(var(--p));
  cursor: pointer;
}

input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
