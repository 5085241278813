.toggle {
  --tglbg: var(--border-color);
  background-color: #fff;
  border: none;

  &:checked {
    --tglbg: hsl(var(--su));
    background-color: #fff;
  }
}
