// TODO: use tailwind vars
.HomePage {
  .list-disc--prelude {
    li {
      display: flex;

      &::before {
        color: var(--highlight-color);
        content: '•';
        font-size: 2em;
        display: inline-block;
        width: 1em;
      }

      // list-style-type: "• ";
      // display: list-item;
      // margin-bottom: 10px;

      // &::marker {
      //   color: var(--highlight-color);
      //   font-weight: bold;
      //   font-size: 1.5em;
      //   margin-right: 0.5em;
      // }
    }
  }

  .Step {
    li {
      @apply text-lg;
      @apply font-normal;
      color: var(--midnightblue-color);
    }

    &__done-message {
      @apply mt-6;
      img {
        @apply mr-3;
      }
    }

    .btn {
      @media (max-width: 440px) {
        background: transparent;
        border: none;
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        width: 100%;

        &::after {
          content: url('../../assets/icons/button-shevron-mobile.svg');
        }

        img {
          display: none;
        }
      }
    }
  }
}
